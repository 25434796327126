/* eslint no-unused-vars: 0 */

import { navigate } from "gatsby";
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Recaptcha from "react-recaptcha";
import theme from "../../theme/theme.yaml";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "", // honeypot
      emailReal: "",
      message: "",
      captchaIsValid: false,
      captchaError: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
    this.expireCaptcha = this.expireCaptcha.bind(this);
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.email != "") return; // honeypot
    if (this.state.captchaIsValid === false) {
      this.setState({
        captchaError: true
      });
      return;
    }

    const b = document.getElementById("submitButton");
    b.disabled = true;
    b.value = "Sending...";
    b.style.transition = "200ms ease-in-out";
    b.style.backgroundColor = theme.color.brand.primaryLight;
    b.style.borderColor = theme.color.brand.primaryLight;
    b.style.color = "#666";

    fetch(this.contactPostAddress, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({ "form-name": "contact", ...this.state })
    })
      .then(function(rawResponse) {
        return rawResponse.json();
      })
      .then(function(response) {
        console.log(response);
        if (response.result == "success") {
          console.log("Form submission success");
          navigate("/success");
        } else {
          console.error(response);
          alert("Server responded with error! Sorry about this.");
        }
      })
      .catch(error => {
        console.error(error);
        alert("Unable to deliver. Is your internet connection down?");
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  verifyCaptcha() {
    this.setState({ captchaIsValid: true, captchaError: false });
  }

  expireCaptcha() {
    this.setState({ captchaIsValid: false, captchaError: true });
  }

  render() {
    const { captchaIsValid, captchaError } = this.state;

    console.log(captchaIsValid, captchaError);
    return (
      <StaticQuery
        query={graphql`
          query ContactQuery {
            site {
              siteMetadata {
                contactPostAddress
              }
            }
          }
        `}
        render={queryResults => {
          // It's very ugly to update this.contactPostAddress inside render(), but Gatsby currently
          // offers only two ways of using StaticQuery: this way (inside render), and as a React Hook
          // (which would be incompatible with a class component).
          const contactPostAddress = queryResults.site.siteMetadata.contactPostAddress;
          this.contactPostAddress = contactPostAddress;

          return (
            <>
              <div className="form">
                <p>
                  Contactez-moi sur{" "}
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/in/kevinnkonda/"
                    alt="profil linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>{" "}
                  ou directement via le formulaire ci-dessous.
                </p>
                <br />
                <form
                  name="contact"
                  method="post"
                  action={contactPostAddress}
                  onSubmit={this.handleSubmit}
                  data-netlify="true"
                >
                  <label className="formItem">
                    Votre prénom:
                    <br />
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <label className="formItem">
                    Votre email:
                    <br />
                    <input
                      type="email"
                      name="emailReal"
                      value={this.state.emailReal}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <input
                    type="email"
                    name="email" // actually honeypot
                    value={this.state.email}
                    onChange={this.handleChange}
                    style={{ display: "none" }}
                  />
                  <label className="formItem">
                    Votre message:
                    <br />
                    <textarea
                      name="message"
                      required={true}
                      value={this.state.message}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <Recaptcha
                    size="normal"
                    sitekey="6LemIMEUAAAAABph3fy4dTaIzGDUcZZWK09kUV6n"
                    verifyCallback={this.verifyCaptcha}
                    expierrorCallback={this.expireCaptcha}
                    hl={"fr"}
                  />
                  {captchaError ? (
                    <p className="captcha-error">Prouvez-moi que vous n&apos;êtes pas un robot</p>
                  ) : null}
                  <br />
                  <input type="submit" value="Envoyer" id="submitButton" className="formItem" />
                  {contactPostAddress === "" && <h1>Undefined contactPostAddress!</h1>}
                </form>

                {/* --- STYLES --- */}
                <style jsx>{`
                  .linkedin {
                    background-image: linear-gradient(
                      transparent 0%,
                      transparent calc(50% - 8px),
                      ${theme.color.brand.primaryLight} calc(50% - 8px),
                      ${theme.color.brand.primaryLight} 100%
                    );
                    transition: background-position 120ms ease-in-out, padding 120ms ease-in-out;
                    background-size: 100% 200%;
                    background-position: 0 0;
                    &:hover {
                      background-image: linear-gradient(
                        transparent 0%,
                        transparent calc(50% - 8px),
                        ${theme.color.brand.primaryLight} calc(50% - 8px),
                        ${theme.color.brand.primaryLight} 100%
                      );
                      background-position: 0 100%;
                    }
                  }
                  .formItem input,
                  textarea {
                    height: 250px;
                    width: 100%;
                    border-style: solid;
                    border-radius: 5px;
                    border-width: 1px;
                    border-color: ${theme.color.neutral.gray.e};
                    font-family: Open Sans;
                    font-weight: 400;
                    font-size: 1em;
                    outline: none;
                    padding: 15px;
                  }
                  .formItem input {
                    height: 30px;
                    max-width: 300px;
                  }
                  .formItem textarea {
                    height: 100px;
                    max-width: 600px;
                  }
                  .captcha-error {
                    color: ${theme.color.brand.primaryDark};
                  }
                  #submitButton {
                    outline: none;
                    color: white;
                    height: auto;
                    font-family: Open Sans;
                    font-size: 1.2em;
                    font-weight: 400;
                    padding: 0.5em 3em;
                    border-radius: 5px;
                    background: ${theme.color.brand.primary};
                    border: 1px solid ${theme.color.brand.primary};
                  }
                  #submitButton:hover {
                    background: ${theme.color.brand.primaryDark};
                    cursor: pointer;
                  }
                `}</style>
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default Contact;
